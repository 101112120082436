import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { options } from "../components/options"
import { Title } from "../components/styles"

const Video = loadable(() => import("../components/video"), {
  fallback: <div></div>,
})

const Data = styled.ul`
  list-style: none;
  padding: 0 !important;

  display: flex;

  li:first-child {
    width: 5em;
  }
`

const Contact = ({ data }) => {
  const {
    contact: { title, image, video, address, telephone, mail, text },
  } = data

  return (
    <Layout>
      <Seo title={title} />
      <main>
        <Title>{title}</Title>
        {video ? (
          <Video title={title} url={video} />
        ) : (
          image && (
            <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
          )
        )}
        {address?.address && (
          <Data>
            <li>
              <strong>Adresse</strong>
            </li>
            <li style={{ whiteSpace: "pre" }}>{address.address}</li>
          </Data>
        )}
        {telephone && (
          <Data>
            <li>
              <strong>Telefon</strong>
            </li>
            <li>{telephone}</li>
          </Data>
        )}
        {mail && (
          <Data>
            <li>
              <strong>Mail</strong>
            </li>
            <li>
              <a href={`mailto:${mail}`}>{mail}</a>
            </li>
          </Data>
        )}
        <article>{text && renderRichText(text, options)}</article>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    contact: contentfulKontakt(
      node_locale: { eq: "de" }
      title: { ne: "C_PH" }
    ) {
      title
      image {
        title
        gatsbyImageData(
          formats: [AUTO, WEBP]
          width: 880
          placeholder: DOMINANT_COLOR
        )
      }
      video
      address {
        address
      }
      telephone
      mail
      text {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              formats: [AUTO, WEBP]
              width: 880
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  }
`

export default Contact
